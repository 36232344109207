#map {
    width: 100vw;
    height: 100vh;
    z-index: 0;
}

.leaflet-bottom.leaflet-left {
    bottom: 35px;
}

.leaflet-touch .leaflet-bar {
    padding: 0.2rem;
    background: white;
    border: none;
    box-shadow: 0px 0px 0.2rem #222;
}

.leaflet-bar a {
    font-weight: bold;
}