.map-wrapper {
    position: relative;
}

.search-div {
    top: 30px;
}

.tos-section, .search-div {
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    left: 0px;
    width: calc(100vw - 0px);
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--white)
}

.search-div .title {
    font-weight: 800;
    font-size: 1.5rem;
    margin: 0 1rem;
}

.search-div .filter-btn {
    font-size: 0.9rem;
    background-color: var(--white);
    color: var(--grey);
    border-radius: 50%;
    padding: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    height: 100%;
}

.search-div .input-div {
    display: flex;
    width: 20vw;
    background-color: white;
    color:var(--grey);
    border-radius: 5px;
    overflow: hidden;
    line-height: 1.8;
}

.input-search:focus + .input-div {
    border: 5px solid red;
}

.input-div .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem 0 0.5rem;
    height: auto;
}

.input-div .input-search {
    width: 100%;
    line-height: 1.8;
    border:none;   
}

::placeholder {
    left: 2rem;
    font-size: 0.75rem;
}

.input-search:focus {
    outline: none;
}

.search-div .cart-button {
    position: absolute;
    top:4px;
    border-radius: 50%;
    padding: 0.25rem;
    width: 30px;
    height: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0.5rem var(--grey);
}

.tos-section {
    bottom: 10px;
}

.tos-section > div, .search-div > div {
    border-radius: 5px;
    padding:0.5rem 0.75rem;
    background-color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tos-section .tos-link {
    text-decoration: none;
    color:var(--white);
    margin:0 1rem;
    font-size: 0.8rem;
    font-weight: bold;
}

/* aside section */
.btn-aside-section {
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 40%;
    box-shadow: 0px 0px 0.2rem var(--grey);
    background-color: var(--white);
    border-radius: 8px;
    padding: 0.5rem 0.25rem;
    width: auto;
}

.btn-aside-section {
    width: 35px;
}

.btn-item {
    width: auto;
    height: auto;
    padding: 0.5rem;
    font-size: 0.9rem;
    color:var(--grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}